import { CapacityLimitsAPIResponse } from "app/types/distribution";
import axios from "./config";
import { AxiosResponse } from "axios";

export const getDeliveryTasks = ({ countryId, query = "" }) =>
  axios.get(`/countries/${countryId}/delivery-tasks${query}`);

export const getDeliveryTaskDetailsById = ({ countryId, id }) =>
  axios.get(`/countries/${countryId}/delivery-tasks/${id}`);

export const getDistributionDriversApi = (query = "") =>
  axios.get(`/drivers${query}`);

export const getDistributionDriversById = (id) => axios.get(`/drivers/${id}`);

export const createDistributionDriversApi = (payload) =>
  axios.post(`/drivers`, payload);

export const updateDistributionDriverById = (id, payload) =>
  axios.put(`/drivers/${id}`, payload);

export const getDriversCredentialsById = (id) =>
  axios.get(`/drivers/${id}/credentials`);

export const getFleetsApi = (query = "") => axios.get(`/fleets${query}`);

export const getCapacityLimitsApi = (
  store: number
): Promise<AxiosResponse<CapacityLimitsAPIResponse>> =>
  axios.get<CapacityLimitsAPIResponse>(`/stores/${store}/capacity-limits`);
