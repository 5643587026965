import {
  PlannedDeliveryAPIResponse,
  PlannedDeliveryByIdAPIResponse,
  UnassignedDeliveryAPIResponse,
} from "app/types/distribution";
import axios from "./config";
import { AxiosResponse } from "axios";

interface Params {
  countryId: number;
  query?: string;
}

export const getPlannedDeliveries = ({
  countryId,
  query = "",
}: Params): Promise<AxiosResponse<PlannedDeliveryAPIResponse>> =>
  axios.get<PlannedDeliveryAPIResponse>(
    `/countries/${countryId}/planned-deliveries${query}`
  );

type GetPlannedDeliveriesByIdParams = {
  countryId: number;
  id: string;
};

export const getPlannedDeliveriesById = ({
  countryId,
  id,
}: GetPlannedDeliveriesByIdParams): Promise<
  AxiosResponse<PlannedDeliveryByIdAPIResponse>
> =>
  axios.get<PlannedDeliveryByIdAPIResponse>(
    `/countries/${countryId}/planned-deliveries/${id}`
  );

export const getUnassignedDeliveriesApi = ({
  countryId,
  query = "",
}: {
  countryId: number;
  query: string;
}): Promise<AxiosResponse<UnassignedDeliveryAPIResponse>> =>
  axios.get<UnassignedDeliveryAPIResponse>(
    `/countries/${countryId}/unassigned-deliveries${query}`
  );
